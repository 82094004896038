import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import {Col, Row} from 'antd';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import {MenuItem} from '../contracts/util';

import Img404 from '../../static/images/404_Graphic.svg';

import '../styles/404.scss';

export interface Props {
	location: Location;
}

export const NotFoundPage = (props: Props) => {
	const { menu } = useStaticQuery(graphql`
      query {
		menu: wpMenu(slug: {eq: "404-menu"}) {
		  id
		  name
		  slug
		  menuItems {
		    nodes {
			  cssClasses
			  label
			  target
			  url
			  parentId
			  id
			  title
			  childItems {
			    nodes {
				  cssClasses
				  label
				  target
				  url
				  parentId
				  id
				  title
				}
			  }
			}
		  }
		}
      }
	`);
	return (
		<Layout location={props.location}>
			<SEO title="404 Error | Page not found" />
			<Row type="flex">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} id="primary" className="content-area text-white">
					<div className="not-found-404">
						<Row type="flex" className="c_404 page-gutter">
							<Col className="md-order-2" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
								<h1>404 Error</h1>
								<h2>The page you are looking for cannot be found.</h2>
								<p>Here are some helpful links instead:</p>
								<ul>
									{menu && menu.menuItems.nodes.length && menu.menuItems.nodes.map((item: MenuItem, itemIndex: number) => {
										return (
											<li key={itemIndex}><a href={item.url} target={item.target} className={item.cssClasses.toString()}>{item.label}</a></li>
										);
									})}
								</ul>
							</Col>
							<Col className="md-order-1" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
								<img src={Img404} alt="404 Error"/>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</Layout>
	);
};

export default NotFoundPage;
